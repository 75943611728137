import { Grid, Typography, useMediaQuery } from '@mui/material';

import { resetStateAction, useUserContext, TCContext } from 'App/context';

import { common } from 'impactApp/translations';

import { ButtonAppearance, Button, ListExpander } from 'components';

import { useContext, useEffect, useState } from 'react';

import { Collapse } from 'react-collapse';

import dompurify from 'dompurify';

import { ModalStyles } from 'components/Dialog/Dialog.styles';

import { useCookies } from 'react-cookie';

import { appConfig } from 'App/appConfig/appConfig';

import { theme, color } from 'App/style';

import { useLocation, useNavigate } from 'react-router-dom';

import { handleOpenedOverlay } from 'App/utils';

import { RoutePaths } from 'impactApp/routes/routePaths';

type TCModalProps = {
  showButtons?: boolean;
};

export const TCModal = ({ showButtons }: TCModalProps) => {
  const { LOGOUT_PAGE } = RoutePaths;
  const navigate = useNavigate();
  const [, dispatch] = useUserContext();
  const { tcValues, setTCValues } = useContext(TCContext);
  const { isTCOpen, text, title, slug, collapsedText } = tcValues;
  const [expanded, setExpanded] = useState(false);
  const { REACT_APP_ACCESS_TOKEN_NAME, REACT_APP_REFRESH_TOKEN_NAME } = appConfig;

  const accessToken = sessionStorage.getItem(REACT_APP_ACCESS_TOKEN_NAME);

  const handleLogout = () => {
    if (accessToken) {
      sessionStorage.removeItem(REACT_APP_ACCESS_TOKEN_NAME);
      localStorage.removeItem(REACT_APP_REFRESH_TOKEN_NAME);
    }
    dispatch(resetStateAction());
    navigate(LOGOUT_PAGE);
  };

  const {
    tc: { accept, reject },
    collapse,
    expand,
  } = common;

  const sanitizer = dompurify.sanitize;

  const isMd = useMediaQuery(theme.breakpoints.up('md'));

  const onAccept = () => {
    // if (setIsTCOpen) setIsTCOpen(true);
  };

  const onClose = () => {
    if (setTCValues) setTCValues({ isTCOpen: false, text: '', title: '', slug: '', collapsedText: '' });
  };

  const location = useLocation();
  const CollapseElement: any = Collapse;

  useEffect(() => {
    if (isTCOpen && slug && slug.length > 0) {
      handleOpenedOverlay(slug as string, location.pathname);
    }
  }, [isTCOpen, location.pathname, slug]);

  return (
    <ModalStyles.Dialog open={isTCOpen} scroll='paper'>
      <ModalStyles.CloseButton
        hoverColor={color.darkHighlight}
        $closeIconColor={theme.palette.primary.main}
        onClick={onClose}
      />
      <ModalStyles.Content id='tc-dialog-content'>
        <ModalStyles.Title variant={isMd ? 'subtitle1' : 'subtitle3'}>{title}</ModalStyles.Title>
        {collapsedText && (
          <Typography variant='body2'>
            <span
              dangerouslySetInnerHTML={{
                __html: sanitizer(collapsedText),
              }}
            />
          </Typography>
        )}
        <CollapseElement isOpened={expanded}>
          {text && (
            <Typography variant='body2'>
              <span
                dangerouslySetInnerHTML={{
                  __html: sanitizer(text),
                }}
              />
            </Typography>
          )}
        </CollapseElement>
        <ModalStyles.Expander>
          <ListExpander
            expandList={() => setExpanded(true)}
            shortenList={() => setExpanded(false)}
            collapseLabel={collapse}
            expandLabel={expand}
            canFetchMore={!expanded}
          />
        </ModalStyles.Expander>
        {showButtons && (
          <ModalStyles.Bottom>
            <Grid container justifyContent='center' spacing={2}>
              <Grid item justifyContent='flex-end'>
                <Button isLarge isDisabled={!expanded} onClick={onAccept} text={accept} id='tc-dialog-accept' />
              </Grid>
              <Grid item justifyContent='flex-start'>
                <Button isLarge appearance={ButtonAppearance.SECONDARY} onClick={handleLogout} text={reject} />
              </Grid>
            </Grid>
          </ModalStyles.Bottom>
        )}
      </ModalStyles.Content>
    </ModalStyles.Dialog>
  );
};
