import { Navigate } from 'react-router-dom';
import { useUserContext } from 'App/context';
import { RoutePaths } from 'impactApp/routes/routePaths';
import { appConfig } from 'App/appConfig/appConfig';
import { Config } from 'App/config';

type PrivateRouteProps = {
  element: JSX.Element;
};

export const PrivateRoute = ({ element }: PrivateRouteProps) => {
  const [{ isLoggedIn }] = useUserContext();
  const { SSO_PAGE } = RoutePaths;
  const { REACT_APP_ACCESS_TOKEN_NAME, REACT_APP_REFRESH_TOKEN_NAME } = appConfig;
  const { REACT_APP_MOCK_API } = Config.getAll();

  const accessToken = sessionStorage.getItem(REACT_APP_ACCESS_TOKEN_NAME);
  const refreshToken = localStorage.getItem(REACT_APP_REFRESH_TOKEN_NAME);

  return isLoggedIn || refreshToken || accessToken || REACT_APP_MOCK_API ? element : <Navigate to={SSO_PAGE} />;
};
