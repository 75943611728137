import { useState, useEffect, Fragment } from 'react';

import { Button, ButtonAppearance } from 'components';

import { handleFootPrintRefinement } from 'App/utils';

import { postRefineTransaction, submitProfileRefinements } from '@ecolytiq/js-data-provider';

import { useTranslation } from 'react-i18next';

import { useDeps, useUserContext } from 'App/context';

import { useNavigate, useLocation } from 'react-router-dom';

import { answerType } from 'impactApp/modules';

import { QuestionsStyles } from './Questions.style';

import { Question } from './Question';

type QuestionsProps = {
  setIsSubmitted: (isSubmitted: boolean) => void;
  setExpanded?: (isExpanded: boolean) => void;
  setAnswers?: (answers: undefined | answerType[]) => void;
  submitButtonText: string;
  saveChanges: string;
  subCategoryValue: string | undefined;
  category: string;
  transactionAnswers: undefined | answerType[];
  isTransactionLoop: boolean;
  transactionId?: string;
};

export const Questions = ({
  setIsSubmitted,
  setAnswers,
  submitButtonText,
  saveChanges,
  subCategoryValue,
  category,
  transactionAnswers,
  isTransactionLoop,
  transactionId,
  setExpanded,
}: QuestionsProps) => {
  const [submitText, setSubmitText] = useState(submitButtonText);
  const navigate = useNavigate();
  const location = useLocation();

  const [content, setContent] = useState([]);
  const { t } = useTranslation();
  const { httpClientService } = useDeps();

  const [isDisabled, setIsDisabled] = useState(false);

  const [{ userId }] = useUserContext();

  const [currentAnswers, setCurrentAnswers] = useState<answerType[]>([]);

  const [oldAnswers, setOldAnswers] = useState<answerType[]>([]);

  useEffect(() => {
    setContent(t(`transactionDetails:feedbackLoop${subCategoryValue}:questions`, { returnObjects: true }));
  }, [t, subCategoryValue]);

  useEffect(() => {
    const fetchData = async () => {
      if (transactionAnswers && transactionAnswers.length > 0) {
        setCurrentAnswers(transactionAnswers);

        setOldAnswers(transactionAnswers);
      } else {
        const emptyAnswers: answerType[] = [];

        content?.forEach((element: any) => {
          emptyAnswers.push({ questionId: element?.questionId, answerId: '', input: '' });
        });
        setCurrentAnswers(emptyAnswers);

        setOldAnswers(emptyAnswers);
      }
    };

    fetchData();
  }, [subCategoryValue, transactionAnswers, content]);

  useEffect(() => {
    let isNotAnswered = false;
    let sameAnswer = true;

    currentAnswers?.forEach((element) => {
      const oldAnswer = oldAnswers?.find((o) => o.questionId === element.questionId);
      if (element.answerId === '' || element.answerId === null) {
        isNotAnswered = true;
      } else if (element.answerId !== oldAnswer?.answerId || element.input !== oldAnswer?.input) {
        sameAnswer = false;
      }
    });
    setIsDisabled(isNotAnswered || sameAnswer);
  }, [currentAnswers, oldAnswers]);

  const handleSubmit = async () => {
    setIsSubmitted(true);
    if (setExpanded) setExpanded(false);
    setOldAnswers(currentAnswers);
    setSubmitText(saveChanges);

    handleFootPrintRefinement(location.pathname, category);

    if (isTransactionLoop && setAnswers) {
      const postAnswers = postRefineTransaction(
        httpClientService,
        transactionId || '',
        currentAnswers.map(({ questionId, ...rest }) => {
          return rest;
        }),
      );

      setAnswers(currentAnswers);
      const { data } = await postAnswers.post();
      navigate(location.pathname, {
        state: {
          transaction: data?.refineTransaction,
        },
      });
    } else {
      const postAnswers = submitProfileRefinements(httpClientService, {
        answers: currentAnswers.map((_) => {
          return {
            account_id: userId,
            answer_id: _.answerId,
            input: _.input,
          };
        }),
      });

      postAnswers.post();
    }
  };
  return (
    <>
      {content?.map((element: any, i, arr) => (
        <Fragment key={element.title}>
          <Question
            question={element?.title}
            id={element?.questionId}
            questionType={element?.type}
            answers={element?.answers}
            oldAnswers={currentAnswers}
            setAnswers={setCurrentAnswers}
            content={content}
            info={element?.info}
          />
          {arr.length - 1 !== i && <QuestionsStyles.Divider />}
        </Fragment>
      ))}
      <QuestionsStyles.ButtonWrapper>
        <Button
          appearance={ButtonAppearance.PRIMARY}
          text={submitText}
          onClick={handleSubmit}
          isDisabled={isDisabled}
        />
      </QuestionsStyles.ButtonWrapper>
    </>
  );
};
