import { Config } from 'App/config';

import { useMutateData, PostSessionResponse, useLogoutSession } from '@ecolytiq/js-data-provider';

import { useNavigate } from 'react-router-dom';

import { useEffect } from 'react';

import { useUserContext, useDeps, resetStateAction } from 'App/context';

import { RoutePaths } from 'impactApp/routes/routePaths';

import { appConfig } from 'App/appConfig/appConfig';

export const useLogoutSessionData = () => {
  const { httpClientServiceOAuth } = useDeps();

  const [{ sessionLogout }, dispatch] = useUserContext();

  const { REACT_APP_ECOLYTIQ_KEYCLOAK_CLIENT_ID } = Config.getAll();

  const navigate = useNavigate();
  const { SSO_PAGE, LOGOUT_PAGE } = RoutePaths;

  const { REACT_APP_ACCESS_TOKEN_NAME, REACT_APP_REFRESH_TOKEN_NAME } = appConfig;

  const refreshToken = localStorage.getItem(REACT_APP_REFRESH_TOKEN_NAME) || '';
  const accessToken = sessionStorage.getItem(REACT_APP_ACCESS_TOKEN_NAME) || '';

  const { mutate: sessionMutate } = useMutateData<PostSessionResponse>(
    useLogoutSession(httpClientServiceOAuth, refreshToken, REACT_APP_ECOLYTIQ_KEYCLOAK_CLIENT_ID as string),
    {
      onSuccess: async () => {
        if (accessToken) {
          sessionStorage.removeItem(REACT_APP_ACCESS_TOKEN_NAME);
          localStorage.removeItem(REACT_APP_REFRESH_TOKEN_NAME);
        }
        dispatch(resetStateAction());
        navigate(LOGOUT_PAGE);
      },
      onError: async () => {
        navigate(SSO_PAGE);
      },
    },
  );
  useEffect(() => {
    if (sessionLogout) sessionMutate();
  }, [sessionLogout, sessionMutate]);
};
